* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: Inter;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Inter;
  src: url(./font/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(./font/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(./font/Inter-SemiBold.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(./font/Inter-Bold.ttf);
}

.ls-page {
  position: relative;
  min-height: 100vh;
  overflow: auto;
}

.ls-logo {
  position: absolute;
  top: 50px;
  left: 0;
  width: 76px;
  height: 79px;
}

.ls-page img {
  width: 100%;
}

.form-wrapper {
  background: #2F96F9;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.ls-heading {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.field>label {
  font-family: Inter;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF !important;
}

.ls-forget-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF;
}

.ls-btn-wrapper {
  border: 1px dashed #FFFFFF;
  border-radius: 50%;
  padding: 1px;
}

.ub-outer-layer {
  border: 2px dashed #78BBFC;
  border-radius: 50%;
}

.bannerHeight {
  height: 72px
}

.ls-login-btn {
  width: 82px !important;
  height: 82px;
  border: 1px dashed #FFFFFF !important;
  border-radius: 50% !important;
  text-align: center !important;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  color: #2F96F9 !important;
  background: #FFFFFF !important;
  padding: 0 !important;
}
.ls-login-btn .p-icon{
  width: auto;
  height: auto;
}
.ls-login-btn .p-button-label{
  transition-duration: 0s !important
}


/* override */

.p-inputtext {
  padding: 12px 9px 11px !important;
  height: 37px;
  /* font-family: 'Apple SD Gothic Neo' !important; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.p-multiselect {
  padding: 12px 9px 11px !important;
  height: 37px;
  /* font-family: 'Apple SD Gothic Neo' !important; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.p-multiselect .p-multiselect-label{
  padding: 0 !important;
}

.p-multiselect .p-multiselect-trigger{
  width: 1rem !important;
}

.p-button:focus {
  box-shadow: 0 0 0 0px #ffffff, 0 0 0 0px #b1b3f8, 0 0px 0px 0 rgb(0, 0, 0) !important;
}

.p-calendar,
.p-calendar .p-inputtext,
.p-calendar .p-button {
  background: #2F96F9 !important;
  color: #FFFFFF;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  border: 0 !important;
  border-radius: 10px;
}

.field>.p-calendar,
.field>.p-calendar .p-inputtext,
.ad-m-s .p-button {

  background: #FAFAFE !important;
  border: 1px solid #F6F5F5;
  color: #000000;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  border-radius: 10px;
  width: 100%;
}

.field .p-dropdown {
  background: #FAFAFE;
  border: 1px solid #F6F5F5;
  border-radius: 10px;
}

.os-time .p-inputtext,
.os-time .p-inputtext,
.os-time .p-calendar,
.os-time .p-button {
  background: #FAFAFE !important;
  color: #191759;
  border: 1px solid #ced4da !important;
  border-radius: 10px;
}

.p-tabview .p-tabview-panels{
  padding: 16px 0 !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
  padding: 10px 1.25rem !important;
  font-size: 14px !important;
}

.pi-star-fill{
  color: #F97802;
}
               

.ad-m-s .p-icon {
  color: #191759;
}

.os-time-inner {
  position: relative;
}

.os-timer-icon {
  position: absolute;
  right: 7px;
  top: 10px;
}

/* ADmin Home */

.s-tb-img{
  cursor: pointer;
}

.ds-admin-wrapper {
  background: #2F96F9;
  /* min-height: 100vh; */
  position: relative;
}

.ds-admin-topbar {
  padding: 21px 15px 22px 14px;
}

.ds-welcome-txt {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}

.ds-wrapper {
  background: #FFFFFF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 17px 16px;
  /* height: 100%; */
  height: calc(100vh - 89px);
}
.us-c-h{
  height: 145px;
}
.us-approval-bg{
  background: #2F96F9;
border: 1px dashed #136CE1;
border-radius: 15px;
padding: 6px 10px;
}
.us-approval-heading{
  font-family: Inter-Bold;
  font-weight: 600;
font-size: 13px;
line-height: 124.2%;
color: #FFFFFF;
padding-bottom: 6px;
}
.us-line{
  border: 1px dashed #78BBFC;
  margin: 6px 0 4px;
}
.us-approval-title{
font-weight: 500;
font-size: 12px;
line-height: 124.2%;
color: #FFFFFF;
padding-bottom: 3px;
}
.us-approval-value{
  font-family: Inter-Regular;
font-weight: 400;
font-size: 11px;
line-height: 124.2%;
color: #FFFFFF;
}
.us-approval-btn{
font-weight: 500;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
padding: 4px 7px 5px 8px;
border: 1px solid #FFFFFF;
border-radius: 10px;
margin-top: 6px;
}
.us-green{
  background: #04B54B;
}
.us-red{
  background: #C20C0C;
}
.us-bottom-btn {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  padding: 0 14px;
}

.ds-opened-box {
  background: #B7DFFF;
  border-radius: 25px;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #0B0A2E;
  margin-right: 10px;
  padding: 52px 0px !important;
  height: fit-content;
}

.ds-opened-box1 {
  background: #EEF1FA;
  border-radius: 25px;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #0B0A2E;
  margin-left: 10px;
  padding: 52px 0px !important;
  height: fit-content;
}

.os-inner {
  padding: 14px;
}

.os-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #191759;
}

.os-card-title {
  margin: 10px 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #797785;
  padding-left: 16px;
}

.os-card-inner {
  background: #FAFAFE;
  border: 1px solid #F6F5F5;
  border-radius: 15px;

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #191759;
  padding: 7px 6px 7px 10px;
  margin-bottom: 5px;
}

.os-checked-btn {
  font-weight: 500;
  color: #FFFFFF;
  padding: 7px 0;
  background: #04B54B;
  border-radius: 10px;
  text-align: center;
}

.os-checkin-btn {
  font-weight: 500;
  color: #FFFFFF;
  padding: 7px 0;
  background: #2F96F9;
  border-radius: 10px;
  text-align: center;
}

.os-calender .p-button,
.os-calender .p-button.p-button-icon-only {
  padding: 0;
}

.oss-inner {
  padding: 17px 0 20px;
}

.oss-line {
  width: 100%;
  height: 0px;
  border: 1px dashed #F3F3F3;
}

.oss-slots {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.oss-add-button {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  background: #2F96F9;
  border-radius: 10px;
  padding: 11px 14px;
  cursor: pointer;
}

.oss-added-text {
  /* font-family: Apple SD Gothic Neo; */
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  margin: 13px 0 11px;
}

.oss-slot-date-calender {
  padding: 9px;
  border-radius: 10px;
  color: #FFFFFF;
  background: #2F96F9;
  border: 1px solid #2F96F9;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.oss-icon-cal {
  width: 12px;
  height: 13.33px;
  object-fit: contain;
  margin-left: 6px;
}

.oss-card-inner {
  background: #FAFAFE;
  border: 1px solid #F6F5F5;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #191759;
  padding: 14px 16px;
  margin-bottom: 5px;
}
.oss-card-inner:hover{
  background: #ececfc;
}
.oss-green-card-inner {
  background: #E9FFF2;
  border: 1px solid #04B54B;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #191759;
  padding: 14px 16px;
  margin-bottom: 5px;
}
.oss-orange-card-inner {
  border: 1px solid #F97802;
  background: #FFF1E3;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #191759;
  padding: 14px 16px;
  margin-bottom: 5px;
}
.oss-red-card-inner {
  background: #FFECEC;
  border: 1px solid #DF0404;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #191759;
  padding: 14px 16px;
  margin-bottom: 5px;
}


.p-sidebar-header {
  justify-content: space-between !important;
  background: #2F96F9;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  color: #FFFFFF;
}

.sb-welcome-text {
  color: #FFFFFF;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
}

.sb-menu {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #191759;
  display: block;
  padding: 12px 0px;
  cursor: pointer;
}

.bk-inner {
  padding: 0 14px;
}

.bk-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #191759;
}

.bk-title {
  color: #797785;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.bk-value {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #191759;
}

.ms-addmember-btn {
  padding: 8px 12px;
  color: #FFFFFF;
  background: #2F96F9;
  border: 1px solid #2F96F9;
  border-radius: 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.ads-form-wrapper .field>label {
  color: #000000 !important;
}

.ads-form-wrapper .p-inputtext {
  background: #FAFAFE;
  border: 1px solid #F6F5F5;
  border-radius: 10px;
}

#large-btn {
  background: #2F96F9;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  width: 100%;
  height: 50px;
  border: 0;
}


.us-sub-text {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.us-green-border {
  padding: 8px 0px;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  background: #E9FFF2;
  border: 1px solid #04B54B;
  border-radius: 10px;
  color: #191759;
  text-align: center;
}

.us-orange-border {
  padding: 8px 0px;
  background: #FFF1E3;
  border: 1px solid #F97802;
  border-radius: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #191759;
  text-align: center;
}

.us-red-border {
  padding: 8px 0px;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  background: #FFECEC;
  border: 1px solid #DF0404;
  border-radius: 10px;
  color: #191759;
  text-align: center;
}


.us-grey-border {
  padding: 8px 0px;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  background: #f0e9e9;
  border: 1px solid #949396;
  border-radius: 10px;
  color: #191759;
  text-align: center;
}

.bs-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #797785;
}

.bs-bold-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #191759;
}

.bs-form-wrapper .field>label {
  color: #797785 !important;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #04B54B !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #04B54B !important;
}

.ub-admin-wrapper {
  background: #FAFAFE;
  padding-bottom: 16px;
  min-height: 100vh;
  position: relative;
}

.bh-card-inner {
  background: #FFFFFF;
  border: 1px solid #F6F5F5;
  border-radius: 15px;

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #191759;
  padding: 7px 6px 7px 10px;
  margin-bottom: 5px;
}

.bh-checkin-btn {
  font-weight: 500;
  color: #FFFFFF;
  padding: 7px 10px;
  background: #2F96F9;
  border-radius: 10px;
  text-align: center;
}

.ub-line {
  border: 1px dashed #78BBFC;
  margin: 10px 0;
}

.ub-ball {
  background: #2F96F9;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  width: 167px;
  padding: 20px 10px 20px;
  height: 167px;
  margin: 1px;
}

.ub-dot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  margin: 10px auto;
}

.ub-ball-title {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.ub-ball-name {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
}

/* .us-bottom-btn{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0 14px;
} */

/* .us-bottom-btn{
  
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  padding-right: 14px;
} */



@media (min-width: 576px) {

  .os-admin-wrapper,
  .ds-admin-wrapper,
  .login-wrapper,
  .ub-admin-wrapper {
    width: 500px;
    margin: 0 auto;
    border: 1px solid #2F96F9;
    min-height: 100vh;
  }
}

a.no-underline.sb-menu {
  position: relative;
  padding: 12px 10px 12px 30px;
}

a.no-underline.sb-menu:before,
a.no-underline.sb-menu:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  position: absolute;
}

a.no-underline.sb-menu:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2F96F9;
  left: 5px;
  top: 15px;
  z-index: 2;
}

a.no-underline.sb-menu:after {
  width: 1px;
  height: 100%;
  background-color: #eee;
  left: 9.5px;
  top: 50%;
  z-index: 1;
}

a.no-underline.sb-menu:last-child:after {
  display: none;
}

a.no-underline.sb-menu {
  position: relative;
  padding: 12px 10px 12px 30px;
}

a.no-underline.sb-menu:before,
a.no-underline.sb-menu:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  position: absolute;
}

a.no-underline.sb-menu:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2F96F9;
  left: 5px;
  top: 15px;
  z-index: 2;
}

a.no-underline.sb-menu:after {
  width: 1px;
  height: 100%;
  border-left: 1px dashed #eee;
  left: 9.5px;
  top: 50%;
  z-index: 1;
}

a.no-underline.sb-menu:last-child:after {
  display: none;
}

.eye-icon-position {
  right: 10px;
  top: 10px;
}

.highlighted {
  border: 2px solid blue;
  background: rgb(177, 177, 238);
  /* Choose the highlight color you prefer */
}

.p-error {
  font-size: 9px;
  margin-top: 5px;
}

input {
  touch-action: manipulation;
}

.floating_btn{
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 14px 14px 0;
  background: #FFFFFF;
}
.cs-save_btn{
  position: absolute;
  bottom: 0;
  margin: 20px 0;
}


/* CarouselDemo.css */

.carousel-demo .product-item .product-item-content {
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  margin: .3rem;
  text-align: center;
  padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
  width: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-icon {
  width: 50px;
  height: 50px;
  border: 4px solid #ccc;
  border-top-color: #2F96F9;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
}
.m-search-box > .p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}